






























































































































































































































































































































































import { Component, Inject as VueInject, Mixins, Prop, Ref, Watch } from 'vue-property-decorator'
import {
  AnyObject,
  Authentication,
  AuthServiceType,
  IModal,
  mapModel,
  ModalType
} from '@movecloser/front-core'

import { ShapeMap, SizeMap, VariantMap } from '../../../../dsl/composables'
import {
  calculateDiscount,
  defaultProvider,
  Inject,
  logger,
  IS_MOBILE_PROVIDER_KEY
} from '../../../../support'
import {
  AllowedAttributes,
  AttributeValue,
  ProductData,
  Variant as ProductVariant
} from '../../../../contexts'
import { StarsRateProps } from '../../../../dsl/molecules/StarsRate'

import { ProductReviewsID } from '../../../../modules/ProductReviews/ProductReviews.config'

import { BaseWishListMixin, IBaseWishListMixin } from '../../../wishlist/shared/mixins/base.mixin'
import { BenefitsBar } from '../../../shared/molecules/BenefitsBar'
import { BenefitProgram } from '../../../loyalty/contracts/programs'
import { BundledItemProps } from '../../../shared/molecules/BundledItem'
import {
  DrawerType,
  IDrawer,
  IStoreService,
  StoreServiceType
} from '../../../shared/contracts/services'
import { Gallery } from '../../../shared/molecules/Gallery'
import { GalleryProps } from '../../../shared/molecules/Gallery/Gallery.contracts'
import { ILoyaltyService, LoyaltyServiceType } from '../../../loyalty/contracts/services'
import { MilesAndMoreCounter } from '../../../loyalty/molecules/MilesAndMoreCounter'
import { openAuthDrawer, UserModel } from '../../../auth/shared'
import { ProductCartMixin } from '../../../checkout/shared/mixins/product-cart.mixin'
import { ToastMixin } from '../../../shared'
import { ToastType } from '../../../shared/services'
import {
  translateProductVariantToGalleryProps
} from '../../../shared/molecules/Gallery/Gallery.helpers'
import WindowScrollMixin from '../../../shared/mixins/windowScroll.mixin'
import { showDeliveryTimer } from '../../../shared/support/delivery-timer'

import { attributesAdapterMap } from '../../models/attributes.adapter'
import BundledProductsList from '../../molecules/BundledProductsList/BundledProductsList.vue'
import { FavouriteProductsServiceType, IFavouriteProductsService } from '../../contracts/services'
import { Modals } from '../../config/modals'
import { NotificationForm } from '../../molecules/NotificationForm'
import { IProductsRepository, ProductsRepositoryType } from '../../contracts/repositories'
import { translateProductVariantToStarsRateProps } from '../../helpers/start-rate'
import {
  translateProductVariantsToVariantsSwitch,
  Variant,
  VariantsSwitch
} from '../../molecules/VariantsSwitch'

import { isAttribute } from '../ProductCard/ProductCard.helpers'

import AllowedAttributesIcons from './partials/AllowedAttributesIcons.vue'
import { GiftBox } from './partials/GiftBox.vue'
import { CartPromotionBox } from './partials/CartPromotionBox.vue'
import {
  ProductHeaderIcons,
  ProductHeaderProps,
  ShippingTimerData,
  Slug, UnavailableBundles
} from './ProductHeader.contracts'
import {
  PRODUCT_HEADER_COMPONENT_KEY,
  PRODUCT_HEADER_DEFAULT_CONFIG
} from './ProductHeader.config'
import DeliveryTimer from './partials/DeliveryTimer.vue'
import PresaleTimer from './partials/PresaleTimer.vue'
import OrderDetails from './partials/OrderDetails.vue'

import PriceNameBar from './partials/PriceNameBar.vue'
import VariantDetailsRating from './partials/VariantDetailsRating.vue'
import { ProductHeaderHelperMixin } from './ProductHeader.mixin'
import marked from 'marked'
import {
  MilesAndMorePayload
} from '../../../loyalty/molecules/MilesAndMoreCounter/MilesAndMoreCounter.contracts'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 *
 */
@Component<ProductHeader>(
  {
    name: 'ProductHeader',
    components: {
      AllowedAttributesIcons,
      BenefitsBar,
      BundledProductsList,
      CartPromotionBox,
      DeliveryTimer,
      GiftBox,
      OrderDetails,
      Gallery,
      MilesAndMoreCounter,
      NotificationForm,
      VariantsSwitch,
      PresaleTimer,
      PriceNameBar,
      VariantDetailsRating
    },
    async created (): Promise<void> {
      this.config = this.getComponentConfig(
        PRODUCT_HEADER_COMPONENT_KEY,
        { ...PRODUCT_HEADER_DEFAULT_CONFIG }
      )
      this.setVariantOnMount()
      this.hasAllGiftsAvailable = await this.checkGiftsAvailability(this.currentVariant)

      if (this.useVendorReviews) {
        await this.loadReviewsBySku()
      }
    },
    mounted (): void {
      this.eventBus.emit('app:product.view', this.getProductViewPayload(this.variant))

      this.initShowTimer()
      this.checkIsFavoriteVariant()
      this.createPriceNameObserver()
      this.canAddBundle = this.checkIfBundleIsAvailable()

      this.loadLoyalty()
    }
  })

export class ProductHeader extends Mixins<
  ProductCartMixin,
  ProductHeaderHelperMixin,
  ToastMixin,
  WindowScrollMixin,
  IBaseWishListMixin
  >(
    ProductCartMixin,
    ProductHeaderHelperMixin,
    ToastMixin,
    WindowScrollMixin,
    BaseWishListMixin) implements ProductHeaderProps {
  @Prop({ type: Object, required: true })
  public readonly product!: ProductData

  @Prop({ type: Object, required: false, default: null })
  public readonly shippingTimer!: ShippingTimerData

  @Inject(AuthServiceType, false)
  private readonly authService?: Authentication<UserModel>

  @Inject(DrawerType, false)
  protected readonly drawerConnector?: IDrawer

  @Inject(FavouriteProductsServiceType, false)
  protected readonly favouriteProductsService?: IFavouriteProductsService

  @Inject(LoyaltyServiceType)
  protected readonly loyaltyService!: ILoyaltyService

  @Inject(ModalType)
  protected readonly modalConnector!: IModal

  @Inject(ProductsRepositoryType)
  protected readonly productsRepository!: IProductsRepository

  @Inject(StoreServiceType, false)
  protected readonly storeService?: IStoreService

  @VueInject({
    from: IS_MOBILE_PROVIDER_KEY,
    default: () => defaultProvider<boolean>(false)
  })
  public readonly isMobile!: () => boolean

  public addToFavoriteBtnLoading: boolean = false

  public addToCartBtnLoading: boolean = false
  public bundledProducts: Array<Omit<BundledItemProps, 'uid'>> = []
  public canAddBundle: boolean = true
  public currentVariantSlug: Slug | null = null
  public currentVariant: ProductVariant<string> | null = null
  public hasAllGiftsAvailable: boolean = false
  public loyaltyPayload: Record<BenefitProgram, Record<string, unknown>> | null = null

  public isFavorite: boolean | undefined = false
  public isNotificationFormVisible: boolean | undefined = false

  public ratingAmountDefault: number = 0
  public ratingAvgDefault: number = 0
  public limitHours: string | null = null

  public renderPriceNameBar: boolean = false
  public showTimer: boolean = false
  public unavailableBundleSkus: Array<UnavailableBundles> = []

  public readonly LAST_ITEMS_AMOUNT: number = 4

  public readonly TOOLTIP_DELIVERY_INFO: string = this.$t(
    'front.products.organisms.productHeader.tooltipInfoDelivery').toString()

  @Ref('productHeader')
  public productHeaderRef!: HTMLDivElement

  @Ref('productHeaderContent')
  public productHeaderContentRef!: HTMLDivElement

  @Watch('bundledProducts')
  private onBundleProductsUpdate () {
    this.canAddBundle = this.checkIfBundleIsAvailable()
  }

  /**
   * Determines the application of current product
   */
  protected get applicationOptions (): string[] | null {
    const application: string | undefined = this.getAttribute<string>(AllowedAttributes.Application)

    if (typeof application === 'undefined') {
      return null
    }

    if (application.includes('/')) {
      return application.split('/')
    }

    return [application]
  }

  public get badges () {
    const badges = []

    if (this.isFinalPriceDifferent) {
      // if (this.isSale) {
      //   badges.push({
      //     label: this.$t('front.products.organisms.productHeader.attributes.isSale').toString(),
      //     theme: 'danger',
      //     shape: ShapeMap.Rectangle,
      //     variant: VariantMap.Full,
      //     size: SizeMap.Medium
      //   })
      // }

      const theme = this.promotionBadgeHasLabel ? 'danger' : 'primary'
      const discountValue = `-${100 - (Math.round((this.variant.price.finalPrice /
        this.variant.price.regularPrice) * 100))}%`
      const label = this.promotionBadgeHasLabel ? discountValue : this.$t(
        'front.products.organisms.productHeader.attributes.isPromotion').toString()
      const shape = this.promotionBadgeHasLabel ? ShapeMap.Square : ShapeMap.Rectangle

      badges.push({
        label,
        theme,
        shape,
        variant: VariantMap.Full,
        size: SizeMap[this.isMobile() ? 'Small' : 'Large']
      })
    }

    if (this.getAttribute('isNatural')) {
      badges.push({
        icon: 'Leaf',
        theme: 'success',
        shape: ShapeMap.Square,
        variant: VariantMap.Outline,
        size: SizeMap[this.isMobile() ? 'Small' : 'Large']
      })
    }

    if (this.getAttribute('hasFreeDelivery')) {
      badges.push({
        icon: 'FreeDelivery',
        theme: '',
        shape: ShapeMap.Square,
        variant: VariantMap.Outline,
        size: SizeMap[this.isMobile() ? 'Small' : 'Large']
      })
    }

    if (this.getAttribute('isNew')) {
      badges.push({
        label: this.$t('front.products.organisms.productHeader.attributes.isNew').toString(),
        theme: 'default',
        shape: ShapeMap.Square,
        variant: VariantMap.Full,
        size: SizeMap[this.isMobile() ? 'Small' : 'Large']
      })
    }

    if (this.getAttribute('isFaF')) {
      badges.push({
        label: this.$t('front.products.organisms.productHeader.attributes.isFaF').toString(),
        theme: 'premium',
        shape: ShapeMap.Square,
        variant: VariantMap.Full,
        size: SizeMap[this.isMobile() ? 'Small' : 'Large']
      })
    }

    if (this.getAttribute('isInPresale')) {
      badges.push({
        label: this.$t('front.products.organisms.productHeader.attributes.isInPresale').toString(),
        theme: 'warning',
        shape: ShapeMap.Square,
        variant: VariantMap.Full,
        size: SizeMap[this.isMobile() ? 'Small' : 'Large']
      })
    }

    if (this.getAttribute(AllowedAttributes.BundledProducts)) {
      badges.push({
        label: 'SET',
        theme: 'default',
        shape: ShapeMap.Square,
        variant: VariantMap.Outline,
        size: SizeMap[this.isMobile() ? 'Small' : 'Large']
      })
    }

    if (this.getAttribute(AllowedAttributes.IsKameleon)) {
      badges.push({
        icon: 'Kameleon',
        theme: 'danger',
        shape: ShapeMap.Square,
        variant: VariantMap.Outline,
        size: SizeMap[this.isMobile() ? 'Small' : 'Large']
      })
    }

    if (this.getAttribute(AllowedAttributes.IsBlackFridayActive)) {
      badges.push({
        label: this.$t('front.products.organisms.productHeader.attributes.isBlackFridayActive').toString(),
        theme: 'promo',
        shape: ShapeMap.Square,
        variant: VariantMap.Full,
        size: SizeMap[this.isMobile() ? 'Small' : 'Large']
      })
    }

    return badges
  }

  /**
   * Determines the list of skus that are in this bundle.
   */
  public get bundledProductsSkus (): string[] | undefined {
    return this.getAttribute<string[]>(AllowedAttributes.BundledProducts)
  }

  public get hasCartPromotions (): boolean {
    const cartPromotions = this.getAttribute<string[]>(AllowedAttributes.CartPromotionsDescriptions)
    return Array.isArray(cartPromotions) && cartPromotions.length > 0
  }

  public get cartPromotionsDescriptions (): string[] {
    return this.getAttribute<string[]>(AllowedAttributes.CartPromotionsDescriptions) || []
  }

  public get wishlistBtnTitle (): string {
    return this.$t(`front.shared.wishlist.${this.isFavorite ? 'remove' : 'add'}`).toString()
  }

  public calculatedDiscount (finalPrice: number, regularPrice: number): string {
    return calculateDiscount(finalPrice, regularPrice)
  }

  /**
   * Determines whether product is bundled and has products.
   */
  public get isBundled (): boolean {
    const bundle = this.getAttribute<string[]>(AllowedAttributes.BundledProducts)
    return Array.isArray(bundle) && bundle.length > 0
  }

  public initialVariantSlug (): Slug {
    const slug = this.$route.query.variant
    let slugs: Record<string, string> = {}
    let full: string

    if (!slug || Array.isArray(slug)) {
      const selectors = Object.entries(this.product.variantSelector || {})
      const parts: string[] = []

      for (const [key, selector] of selectors) {
        if (selector.length > 0) {
          if (selector[0] && 'slug' in selector[0]) {
            slugs[key] = selector[0].slug
            parts.push(selector[0].slug)
          }
        }
      }

      full = parts.length ? parts.join('-') : '_'
    } else {
      slugs = this.product.variants[slug].identifier
      full = slug
    }

    return {
      ...slugs,
      full
    }
  }

  protected initShowTimer () {
    if (!this.siteService || this.isPresale) {
      return
    }

    const shippingTimer = this.siteService.getProperty('shippingTimer') as ShippingTimerData | undefined
    const { shouldShowTimer } = showDeliveryTimer(shippingTimer)

    this.showTimer = shouldShowTimer
    this.limitHours = shippingTimer ? shippingTimer.limitHours : '17'
  }

  public get variantRating (): number {
    return this.variant.rating?.average.rate ?? this.ratingAmountDefault
  }

  public get ratingAmount (): number {
    return this.variants.map((variant) =>
      variant.rating?.average.amount ?? this.ratingAmountDefault)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  }

  public get ratingAvg (): number {
    return this.variants.map((variant) =>
      variant.rating?.average.rate ?? this.ratingAmountDefault)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  }

  public get canAddToCart (): boolean {
    if (this.isBundled) {
      return this.canAddBundle
    }
    return this.variant.isAvailable && this.variant.sellableQuantity > 0
  }

  public get deliveryTimeInfo () {
    if (!this.storeService) {
      return null
    }

    try {
      const info = this.storeService.deliveryInfo
      const day = this.$t(`front.products.organisms.productHeader.infoBarEntryDays.${info.day}`)

      return {
        ...info,
        day
      }
    } catch (e) {
      this.notify((e as Error).message, ToastType.Danger)
    }
  }

  public get galleryProps (): GalleryProps {
    return {
      ...translateProductVariantToGalleryProps(this.variant),
      badges: this.badges
    }
  }

  public get hasAdvancedVariants (): boolean {
    if (!this.product.variantSelector) {
      return false
    }

    return Object.keys(this.product.variantSelector).some(v => this.variantsSwitchType(v) ===
      'advanced')
  }

  public get hasGiftsBox (): boolean {
    return this.getConfigProperty('hasGiftsBox') &&
      this.currentVariant?.attributes[AllowedAttributes.HasGift] as boolean &&
      this.hasAllGiftsAvailable
  }

  public get averageTrustedShopsProductRating (): number {
    return this.$store.getters['products/getAverageProductRating']
  }

  public get milesAndMorePayload (): MilesAndMorePayload | undefined {
    if (!this.loyaltyPayload) {
      return
    }
    return this.loyaltyPayload[BenefitProgram.MilesAndMore]
  }

  /**
   * Determines whether to use review from external vendor
   */
  public get useVendorReviews (): boolean {
    return this.getConfigProperty('useVendorReviews')
  }

  public get defaultMaxRating (): number {
    return this.getConfigProperty('defaultMaxRating')
  }

  public get shouldHaveVisibleRating (): boolean {
    return this.getConfigProperty('shouldHaveVisibleRating')
  }

  /**
   * Determines icon for the "notify me" button.
   */
  public get notifyButtonIcon (): string {
    return this.getConfigProperty('notifyButtonIcon')
  }

  /**
   * Determines whether product header gallery has badges.
   */
  public get badgesOnGallery (): boolean {
    return this.getConfigProperty('badgesOnGallery')
  }

  public get favouriteAsIcon (): boolean {
    return this.getConfigProperty('favouriteAsIcon')
  }

  public get hasBenefitsBar (): boolean {
    return this.getConfigProperty('hasBenefitsBar')
  }

  /**
   * Determines whether product header has delivery timer.
   */
  public get hasDeliveryTimer (): boolean {
    return this.getConfigProperty('hasDeliveryTimer')
  }

  /**
   * Determines the capacity based attributes of the variant (ex: volume, weight)
   */
  public get displayableCapacityAttributes (): string[] {
    const toReturn: string[] = []

    if (this.shouldDisplayVolume) {
      toReturn.push('volumeName')
    }

    if (this.shouldDisplayWeight) {
      toReturn.push('weightName')
    }

    return toReturn
  }

  /**
   * Determines whether product header has a "notify me" button.
   */
  public get hasNotificationForm (): boolean {
    return this.getConfigProperty('hasNotificationForm')
  }

  /**
   * Determines whether product header has order details.
   */
  public get hasOrderDetails (): boolean {
    return this.getConfigProperty('hasOrderDetails')
  }

  /**
   * Determines whether promotion badge has label with discount value.
   */
  public get promotionBadgeHasLabel (): boolean {
    return this.getConfigProperty('promotionBadgeHasLabel')
  }

  /**
   * Determines whether chosen variant value is showed.
   */
  public get variantSwitcherShowChosen (): boolean {
    return this.getConfigProperty('variantSwitcherShowChosen')
  }

  /**
   * Determines whether to show rating only for current variant instead of aggregate
   */
  public get showSingleVariantRating (): boolean {
    return this.getConfigProperty('showSingleVariantRating')
  }

  public get icons (): ProductHeaderIcons {
    return this.getConfigProperty('icons')
  }

  /**
   * Determines whether rating should be formated as: "rate / maximum rate" instead of "rate (maximum rate)"
   */
  public get shouldHaveSeparatedRating (): boolean {
    return this.getConfigProperty<boolean>('shouldHaveSeparatedRating')
  }

  public get shouldDisplayVolume (): boolean {
    return !!this.variant.attributes.volumeName
  }

  public get shouldDisplayWeight (): boolean {
    return !!this.variant.attributes.weightName
  }

  public get hasDiscount (): boolean {
    if (!this.variant) {
      return false
    }
    const {
      finalPrice,
      regularPrice
    } = this.variant.price

    return finalPrice < regularPrice
  }

  public get hasVariants (): boolean {
    return Object.keys(this.product.variants).length > 1
  }

  public get variantBrandUrl (): string {
    const brandUrl = this.variant.attributes[AllowedAttributes.BrandUrl]
    return brandUrl ? brandUrl.toString() : ''
  }

  public get variantCategoryUrl (): string {
    const categoryUrl = this.variant.attributes[AllowedAttributes.MainCategoryUrl]
    return categoryUrl ? categoryUrl.toString() : ''
  }

  public get ldJson (): AnyObject {
    return {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: this.variant.name,
      image: this.variant.images[0].url,
      description: marked.parseInline(this.variant.attributes.mainDescription)
        .replace(/(<([^>]+)>)/gi, '')
        .replace(/\n/gi, ' '),
      brand: {
        '@type': 'Brand',
        name: this.getAttribute('brand')
      },
      sku: this.variant.sku,
      offers: {
        '@type': 'Offer',
        url: this.siteService.getActiveSiteAddress() + this.variant.link,
        priceCurrency: 'PLN',
        price: this.variant.attributes.isGift ? 0 : this.variant.price.finalPrice,
        availability: this.isPresale
          ? 'https://schema.org/PreSale'
          : this.canAddToCart ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
        itemCondition: 'https://schema.org/NewCondition'
      },
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: this.variant.rating?.average.rate,
        reviewCount: this.variant.rating?.average.amount,
        bestRating: 5,
        worstRating: 1
      }
    }
  }

  public variantsSwitchType (key: string): string {
    if (!this.product.variantSelector || !(key in this.product.variantSelector)) {
      throw new Error('key not found')
    }

    const elements = this.product.variantSelector[key]

    if (elements.length === 0) {
      return ''
    }

    if (elements.some(({ value }) => !!value)) {
      return 'default'
    }

    return 'advanced'
  }

  public get isFavoriteVariant (): boolean | undefined {
    return this.isFavorite
  }

  /**
   * Determines the whether final price is different.
   */
  public get isFinalPriceDifferent (): boolean {
    return this.hasDiscount
  }

  public get isPresale (): boolean {
    return !!this.getAttribute(AllowedAttributes.IsPresale)
  }

  public get percentageDiscount (): string {
    return `-${100 - (Math.round((this.variant.price.finalPrice / this.variant.price.regularPrice) * 100))}%`
  }

  public get presaleEndDate (): string | undefined {
    return this.getAttribute(AllowedAttributes.PresaleEndDate)
  }

  public get productReviewsIdentifier () {
    return ProductReviewsID
  }

  public get starsRateProps (): Omit<StarsRateProps, 'model'> {
    return translateProductVariantToStarsRateProps(this.variant, false)
  }

  public get variant (): ProductVariant<string> {
    if (!this.currentVariant) {
      throw new Error('Variant not set')
    }

    return {
      ...this.currentVariant,
      attributes: mapModel(this.currentVariant.attributes, attributesAdapterMap, false)
    }
  }

  public variantLabel (key: string, variant: ProductVariant<string>): string | undefined {
    if (!this.product.variantSelector) {
      return ''
    }

    return this.product.variantSelector[key].find((selector) => {
      return (selector && 'slug' in selector) && selector.slug === variant.identifier[key]
    })?.label
  }

  public get variantLastItems (): boolean {
    return this.variant.sellableQuantity <= this.LAST_ITEMS_AMOUNT
  }

  public get variants (): ProductVariant<string>[] {
    return Object.values(this.product.variants)
  }

  public get variantPriceWithoutDelivery (): number {
    return this.variant.price.finalPrice - ((this.cart && this.cart.selectedShippingMethod) ? this.cart.selectedShippingMethod.price.value : 0)
  }

  public get modalSize (): string {
    return this.getConfigProperty<string>('modalSize')
  }

  public get useDrawer (): boolean {
    return this.getConfigProperty<boolean>('useDrawer')
  }

  public variantsSwitchProps (type = 'color'): Variant[] {
    return translateProductVariantsToVariantsSwitch(this.product, type)
  }

  public onBundlesUpdate (products: Array<Omit<BundledItemProps, 'uid'>>) {
    this.bundledProducts = products
  }

  public get unavailableBundleChildSku (): string | undefined {
    if (this.unavailableBundleSkus.length === 0) {
      return
    }

    // Return just a first found child sku, because notification form currently can't handle multiple skus
    return this.unavailableBundleSkus.filter((item) => item.sellableQuantity < 1)[0].sku
  }

  public async onAddToCart (): Promise<void> {
    if (!this.cartService) {
      return
    }

    const isBundlePresale = this.isBundleInPresale(this.bundledProducts)
    if (isBundlePresale) {
      return
    }

    this.canAddBundle = this.checkIfCanAddBundle()
    if (!this.canAddBundle) {
      this.showBundleQuantityErrorToast()
      return
    }

    this.addToCartBtnLoading = true

    const canAddPresale = await this.canAddPresale(this.isPresale, this.variant.sku)

    if (!canAddPresale) {
      this.addToCartBtnLoading = false
      return
    }

    try {
      await this.addToCart(
        this.variant,
        1,
        true,
        this.modalSize,
        this.isMobile() ? this.useDrawer : false
      )
    } catch (e) {
      this.notify((e as Error).message, ToastType.Danger)
    } finally {
      this.addToCartBtnLoading = false
    }
  }

  private checkIfBundleIsAvailable (): boolean {
    const isBundleElement = this.variant.attributes[AllowedAttributes.IsInBundles] as unknown as Record<string, Array<string>>
    if (isBundleElement) {
      return true
    }

    const variantBundles = this.variant.attributes[AllowedAttributes.BundledProducts] as string[]

    if (!!variantBundles && variantBundles.length > 0 && this.bundledProducts.length > 0) {
      for (const product of this.bundledProducts) {
        if (!product.isAvailable) {
          this.unavailableBundleSkus.push({
            sellableQuantity: product.sellableQuantity,
            sku: Object.values(product.product.variants)[0].sku
          })
        }
      }

      return this.bundledProducts.every(product => product.sellableQuantity > 0)
    }

    return true
  }

  private checkIfCanAddBundle (): boolean {
    const variantBundles = this.variant.attributes[AllowedAttributes.BundledProducts] as string[]

    if (!!variantBundles && variantBundles.length > 0 && this.bundledProducts.length > 0 && this.cart && this.cart.items.length > 0) {
      for (const product of this.bundledProducts) {
        const cartProduct = this.cart.items.find((item) => item.sku === product.sku)

        if (cartProduct && (cartProduct.quantity + 1) > product.sellableQuantity) {
          this.unavailableBundleSkus.push({
            sellableQuantity: product.sellableQuantity - cartProduct.quantity,
            sku: Object.values(product.product.variants)[0].sku
          })
        }
      }

      if (this.unavailableBundleSkus.length > 0) {
        return false
      }
    }

    return true
  }

  private showBundleQuantityErrorToast (): void {
    if (!this.toastDefaultOptionsConfig) {
      return
    }
    this.showToast(this.$t('front.products.organisms.productHeader.bundleQuantityWarning').toString(), 'danger', '', {
      ...this.toastDefaultOptionsConfig,
      dismissible: true,
      duration: 5000
    })
  }

  public onNotifyMe (): void {
    this.isNotificationFormVisible = true
  }

  public async addToFavorite (): Promise<void> {
    this.addToFavoriteBtnLoading = true

    try {
      await this.add({
        sku: this.variant.sku,
        quantity: 1
      })
      this.isFavorite = true
    } catch (e) {
      this.notify((e as Error).message, ToastType.Danger)
    } finally {
      this.addToFavoriteBtnLoading = false
    }
  }

  public authCheck (): boolean {
    return this.authService?.check() || false
  }

  public async checkIsFavoriteVariant (): Promise<void> {
    this.isFavorite = this.isInWishlist(this.variant.sku)
  }

  public generateCategoryLink (categoryTree: AnyObject, name: string): string {
    if (categoryTree && categoryTree.parent) {
      return this.generateCategoryLink(categoryTree.parent, `${categoryTree.slug}/${name}`)
    } else {
      return `/${name.toLowerCase().slice(0, -1)}`
    }
  }

  /**
   *
   * TODO: Should be operated by key, not by text.
   * Gets the application of the active variant.
   */
  public getApplication (application: string): undefined | string {
    switch (application) {
      case 'dzień':
        return 'DayIcon'
      case 'noc':
        return 'NightIcon'
      case 'dzień/noc':
        return 'DayNightIcon'
    }
  }

  public async handleFavoriteAction (): Promise<void> {
    if (!this.isFavorite) {
      return await this.addToFavorite()
    }

    await this.removeFromFavorite()
  }

  public leaveReview (): void {
    if (!this.authCheck() && this.drawerConnector) {
      return openAuthDrawer(this.drawerConnector)
    }

    const variantWithColor = this.product.variantSelector?.color?.find(({ slug }) => {
      return slug === this.variant.identifier.color
    })
    if (!variantWithColor) {
      return
    }

    this.modalConnector.open(Modals.AddReviewModal, {
      title: this.variant.attributes[AllowedAttributes.ProductLine],
      description: this.variant.name,
      variantHex: variantWithColor.value,
      variant: 'color',
      sku: this.variant.sku
    })
  }

  public onUpdateVariant (slug: string, type = 'color') {
    if (!this.currentVariantSlug) {
      throw new Error('Variant slug not set')
    }

    const currentSlug = this.currentVariantSlug
    const newSlug: string[] = []
    const selectorKeys = Object.keys(this.product.variantSelector || {})
    selectorKeys.forEach((key) => {
      if (key === type) {
        newSlug.push(slug)
        return
      }

      newSlug.push(currentSlug[key])
    })

    this.currentVariantSlug[type] = slug
    slug = newSlug.join('-')

    this.setVariant(slug)
    this.checkIsFavoriteVariant()

    this.$router.push({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        variant: slug
      }
    })
  }

  public setVariant (slug: string): void {
    const foundVariant = this.product.variants[slug]
    if (!foundVariant) {
      return
    }

    this.currentVariant = foundVariant
  }

  public setVariantOnMount (): void {
    this.currentVariantSlug = this.initialVariantSlug()
    this.setVariant(this.currentVariantSlug.full)
  }

  public async removeFromFavorite (): Promise<void> {
    this.addToFavoriteBtnLoading = true

    try {
      await this.remove(this.variant.sku)
      this.isFavorite = false
    } catch (e) {
      this.notify((e as Error).message, ToastType.Danger)
    } finally {
      this.addToFavoriteBtnLoading = false
    }
  }

  protected createPriceNameObserver (): void {
    if (!this.productHeaderContentRef) {
      return
    }

    const observer = new IntersectionObserver(this.handlePriceNameDisplay, {
      root: null,
      rootMargin: '0px',
      threshold: 0
    })
    observer.observe(this.productHeaderContentRef)
  }

  protected handlePriceNameDisplay (entries: IntersectionObserverEntry[]): void {
    entries.forEach((entry) => {
      this.renderPriceNameBar = !entry.isIntersecting
    })
  }

  protected getAttribute<R extends AttributeValue | AttributeValue[]> (attribute: string): R | undefined {
    if (!this.variant || typeof this.variant === 'undefined') {
      return
    }

    if (!isAttribute(attribute)) {
      return undefined
    }

    return attribute in this.variant.attributes
      ? this.variant.attributes[attribute] as R : undefined
  }

  protected notify (message: string, type: ToastType): void {
    this.showToast(message, type)
  }

  /**
   * Loads TrustedShop product reviews by sku
   * @protected
   */
  protected async loadReviewsBySku (): Promise<void> {
    if (!this.product) {
      return
    }

    this.eventBus.emit('product:trustedShop-global-loading', true)

    const skus: string[] = []

    for (const variant of Object.values(this.product.variants)) {
      skus.push(variant.sku)
    }

    if (skus.length === 0) {
      return
    }

    try {
      await this.loadProductReviews(skus)
    } catch (e) {
      logger(e, 'warn')
    } finally {
      this.eventBus.emit('product:trustedShop-global-loading', false)
    }
  }

  protected async loadLoyalty (): Promise<void> {
    const fixedPrograms = this.getConfigProperty<Array<BenefitProgram>>('fixedPrograms', this.config)
    const loyalty = await this.loyaltyService.fetch()
    const currentPrograms = loyalty.getCurrentPrograms()

    if (!currentPrograms.length) {
      return
    }

    const programsPayloadEntries: Array<Array<BenefitProgram | Record<string, unknown>>> = currentPrograms
      .map((program: string) => {
        const payload = loyalty.getPayload(fixedPrograms)

        switch (program) {
          case BenefitProgram.MilesAndMore:
            return [program, {
              milesAndMorePoints: payload.milesAndMorePoints,
              milesAndMorePointsLimit: payload.milesAndMorePointsLimit
            }]
          // case: Other programs...
          default:
            return []
        }
      })
      .filter((entries: Array<BenefitProgram | Record<string, unknown>>) => !!entries && entries.length > 0)

    this.loyaltyPayload = Object.fromEntries(programsPayloadEntries)
  }
}

export default ProductHeader
